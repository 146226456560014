/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-23 11:05:55
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-23 15:51:46
 * @FilePath: \qctsw-vue\packages\common\src\navigator\company.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum COMPANY_ROUTE_PATH {
  home = '/', // 首页
  login = '/company', // 登录
}

export const COMPANY_ROUTER = {
  login: () => COMPANY_ROUTE_PATH.login,
  home: () => COMPANY_ROUTE_PATH.home,
}
