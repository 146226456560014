<script setup lang='ts'>
import { APP_ROUTER, getSortAreaText } from '@qctsw/common'
import { logicHeaderComplaintList } from '@/logic/common/headerComplaint'

const {
  list,
  getHeaderCompliantList,
} = logicHeaderComplaintList()

await getHeaderCompliantList()
</script>

<template>
  <n-carousel
    v-if="list?.length"
    direction="vertical" :show-dots="false" show-arrow :autoplay="true" mousewheel
    class="pr-3 relative w-fit max-w-150"
  >
    <NuxtLink
      v-for="v, i in list" :key="i"
      class="flex items-center justify-end h-full"
      :to="APP_ROUTER.complaintDetail(v.id)"
    >
      <span class="truncate">{{ v.title }}</span>
      <span class="flex-shrink-0">{{ `（${getSortAreaText({ provinceId: v.portalUserProvinceId, cityId: v.portalUserCityId })}${(v.carUserName || '').slice(0, 1)}先生）` }}</span>
    </NuxtLink>

    <template #arrow="{ prev, next }">
      <button class="absolute top-0 h-full right-0 flex flex-col items-center justify-center text-2.5">
        <i i-el:caret-up @click="prev" />
        <i i-el:caret-down @click="next" />
      </button>
    </template>
  </n-carousel>
</template>

<style lang='scss' scoped>

</style>
