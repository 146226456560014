<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-21 16:02:42
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-08-16 17:17:40
 * @FilePath: \qctsw-vue\packages\app\src\layouts\default.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script lang="ts" setup>
</script>

<template>
  <LayoutHeader v-show="!$route.query.isSite" />
  <template v-if="$route.query.isSite">
    <div class="w-full  bg-slate-1 py-5">
      <div class="w-300 m-auto rounded h-30 bg-red" />
    </div>
  </template>
  <main> <slot /></main>
  <LayoutFooter />
</template>
