<script setup lang='ts'>
import { getCurrentTime, isClient } from '@qctsw/utils'
import { APP_ROUTER } from '@qctsw/common'
import { logicHelp, logicHelpDetail } from '@/logic/common/help'

const currentYear = computed(() => getCurrentTime('YYYY'))
const imgs = {
  进入小程序: '/images/qctsw_mp_weixin.png',
  关注公众号: '/images/qctsw_open_qrcode.jpg',
  投诉问题联系我们: '/images/customer.jpg',
}

const {
  data,
  getAllHelp,
} = logicHelp()

const {
  data: linkData,
  getHelp,
} = logicHelpDetail()
if (isClient) {
  onMounted(() => {
    getHelp(1, { onlyClient: true })
  })
}

await getAllHelp()
</script>

<template>
  <footer class="bg-slate-8 text-white border-t-1 border-slate-6 pt-sm">
    <div class="public-container flex items-start pt-xl">
      <ul class="grid grid-cols-3 gap-xl flex-grow min-w-0 text-3.5 pr-4xl">
        <li v-for="v, i in data" :key="i" class="line-clamp-1">
          <NuxtLink :to="APP_ROUTER.helpDetail(v.id)" class="hover:text-orange-6">
            {{ v.title }}
          </NuxtLink>
        </li>

        <li>法律合作团队：大篆律师事务所</li>
      </ul>
      <div class="flex-shrink-0">
        <div class="grid grid-cols-3 gap-xl">
          <div v-for="v, key in imgs" :key="key" class="flex flex-col items-center">
            <NImage class="code flex-shrink-0" :src="v" fit="contain" />
            <div class="flex justify-center mt-2 text-3">
              {{ key }}
            </div>
          </div>
        </div>
        <p v-if="linkData && linkData.content" text-3 text-right mt-sm>
          {{ `如有问题导致无法正常使用请联系: ${linkData.content?.trim()}` }}
        </p>
      </div>
    </div>
    <div class="border-t-1 border-slate-6 mt-sm">
      <div class="py-xl text-3 public-container flex items-center justify-between">
        <span><span font-mono>Copyright © 2008 - {{ currentYear }}</span> 汽车投诉网</span>
        <span>
          <a href="https://beian.miit.gov.cn/" target="_blank" class="hover:text-orange-6">粤ICP备12070654号 </a>
          <span class="border-l-2 border-slate-6 h-2.5 inline-block pr-1 ml-1" /> 广州哲恒企业管理咨询有限公司 版权所有
        </span>
      </div>
    </div>
  </footer>
</template>

<style lang='scss' scoped>
.code {
  --uno: w-25 h-25 bg-white;
}
</style>
