<script setup lang='ts'>
import type { FormInst, FormItemRule } from 'naive-ui'
import { logicAccountLogin, logicLoginSubmit } from '@/logic/user/login'

const rules = {
  account: [
    {
      validator: (_rule: FormItemRule, value: string) => {
        if (!value)
          return new Error('请输入账号/手机号/邮箱')
        return true
      },
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur',
    },
  ],
}

const formRef = ref<FormInst | null>(null)
const loading = ref(false)
const isVerifySuccess = ref(false)
const accountInfo = ref({
  account: '',
  password: '',
})

async function loginSubmit() {
  logicLoginSubmit({
    formRef,
    loading,
    fn: () => logicAccountLogin({
      account: accountInfo.value.account,
      password: accountInfo.value.password,
    }),
  })
}
</script>

<template>
  <NForm
    ref="formRef"
    :label-width="80"
    :model="accountInfo"
    :rules="rules"
    size="large"
  >
    <NFormItem path="phone" :show-label="false">
      <NInput
        v-model:value="accountInfo.account"
        class="bg-slate-2! rounded-md"
        size="large"
        placeholder="账号/手机号/邮箱"
      />
    </NFormItem>
    <NFormItem path="password" :show-label="false">
      <NInput
        v-model:value="accountInfo.password"
        size="large"
        type="password"
        class=" bg-slate-2! rounded-md"
        placeholder="请输入密码"
      />
    </NFormItem>
  </NForm>
  <UtilDragVerify v-model="isVerifySuccess" class="mb-6" />
  <button
    class="block w-full text-white py-2 rounded-md"
    :class="[loading ? 'cursor-not-allowed bg-blue-3' : 'bg-blue-5 cursor-pointer']"
    :disabled="loading"
    @click="loginSubmit"
  >
    <i v-if="loading" class="i-eos-icons:loading mr-1 vertical-icon" />
    <span v-else>登录</span>
  </button>
</template>

<style lang='scss' scoped>

</style>
