<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-22 11:55:16
 * @FilePath: \qctsw-vue\packages\app\src\components\UtilNotification.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup lang='ts'>
import { APP_ROUTER } from '@qctsw/common'
import { logicUserNotification } from '@/logic/user/notification'

const {
  notifications,
  getNotifications,
  readNotification,
  goPage,
} = logicUserNotification({
  isNewNotification: true,
})

async function visibleAfterHandler(val: boolean) {
  // 手动已读
  return
  if (!val)
    return

  try {
    const promises: any[] = []
    if (notifications.value?.filter(v => v.readStatus !== 1)?.length)
      promises.push(readNotification(notifications.value?.filter(v => v.readStatus !== 1)?.[0]?.id))
    await Promise.all(promises)
  }
  catch {}
}

getNotifications()
</script>

<template>
  <NPopover trigger="hover" @update:show="visibleAfterHandler">
    <template #trigger>
      <NuxtLink :to="APP_ROUTER.userNotification()" class="relative">
        <i class="i-ep:bell text-6.5 vertical-icon" />
        <div v-if="notifications?.filter(v => v.readStatus !== 1)?.length" class="w-2 h-2 rounded-full absolute top-0 right-0 bg-red-5" />
      </NuxtLink>
    </template>
    <div v-if="notifications.length" class="divide-y divide-slate-2 w-50 ">
      <div v-for="v, i in notifications" :key="i" class="py-2">
        <p class="line-clamp-2 text-4 ">
          {{ v.notifyContent }}
        </p>
        <div class="flex items-center justify-between text-3 mt-2">
          <span v-if="v.dataId" class="text-green-5 cursor-pointer" @click="goPage(v)">
            查看相关详情
          </span>
          <span v-if="v.readStatus === 0" class="text-blue-5 cursor-pointer" @click="readNotification(v.id)">标记已读</span>
        </div>
      </div>
      <RouterLink to="/user/notification" class="cursor-pointer text-center text-blue-5 block pt-2">
        查看全部
      </RouterLink>
    </div>
    <div v-else class="text-3 text-info text-center">
      暂无消息
    </div>
  </NPopover>
</template>

<style lang='scss' scoped>

</style>
