import { LOAD_STATE } from '@/shared/types'
import { getHeaderCompliantListApi } from '@/server'
import type { HeaderCompliantResponse } from '@/server/types'

export function logicHeaderComplaintList() {
  const state = ref<LOAD_STATE>(LOAD_STATE.INIT)
  const list = ref<HeaderCompliantResponse[]>([])

  async function getHeaderCompliantList() {
    const res = await useLoadState({
      key: `HEADER_COMPLAINT_LIST`,
      state,
      fn: getHeaderCompliantListApi,
    })
    list.value = res?.data || []
  }

  return {
    list,
    getHeaderCompliantList,
  }
}
