<script setup lang='ts'>
const props = withDefaults(defineProps<{
  modelValue?: boolean
  tips?: string
  successText?: string
  startText?: string
}>(), {
  modelValue: false,
  successText: '验证成功',
  startText: '拖动滑块到最右侧',
})
const emit = defineEmits({
  'update:modelValue': (_value: boolean) => true,
})
watch(() => props.modelValue, (nv) => {
  if (!nv)
    init()
})
function init() {
  const ele = document.getElementById('slider')
  if (ele)
    ele.style.transform = `translateX(${0}px)`
}
const statusWidth = ref<number>(0)

function rangeMove(e: any) {
  const ele = document.getElementById('slider') ? document.getElementById('slider') : e.target
  let disX = 0
  const startX = e.clientX
  const eleWidth = ele.offsetWidth
  const parentWidth = ele.parentElement.offsetWidth
  const MaxX = parentWidth - eleWidth
  if (props.modelValue) { // 不运行
    return false
  }
  document.onmousemove = (e) => {
    const endX = e.clientX
    disX = endX - startX
    if (disX <= 0)
      disX = 0

    if (disX >= MaxX - eleWidth) { // 减去滑块的宽度,体验效果更好
      disX = MaxX
    }
    statusWidth.value = disX
    ele.style.transition = '.1s all'
    ele.style.transform = `translateX(${disX}px)`

    e.preventDefault()
  }
  document.onmouseup = () => {
    if (disX !== MaxX) {
      ele.style.transition = '.5s all'
      ele.style.transform = 'translateX(0)'
      emit('update:modelValue', false)
    }
    else {
      emit('update:modelValue', true)
    }
    statusWidth.value = 0
    document.onmousemove = null
    document.onmouseup = null
  }
}
</script>

<template>
  <div class="slider-range is-active" :class="[props.modelValue ? 'success' : '']">
    <div class="status" :class="props.modelValue ? 'success' : ''" :style="{ width: `${statusWidth}px` }" />
    <div id="slider" class="slider" :class="[props.modelValue && statusWidth ? 'right-0' : 'left-0']" @mousedown="rangeMove">
      <i v-if="props.modelValue" class="i-icon-park-solid:success text-5 text-green-5" />
      <i v-else class="i-material-symbols:double-arrow text-6 text-slate-8" />
    </div>
    {{ props.modelValue ? successText : startText }}
  </div>
</template>

<style lang='scss' scoped>
.slider-range {
  &.is-active {
    opacity: 1;
  }
  opacity: 0.5;
  background-color: #eaecf2;
  position: relative;
  transition: 1s all;
  user-select: none;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #eaecf2;
  border-top-width: 1px;
}
.slider-range .slider {
  position: absolute;
  width: 60px;
  height: calc(100%);
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.icon {
  --uno:  w-7.5 h-7.5;
}
.success {
  --uno: bg-green-5 text-white border-1 border-green-5;
  .icon {
    --uno: text-green-5 w-5 h-5;
  }
}
.status {
  --uno: bg-green-5 rounded-l-md absolute left-0 top-0 bottom-0;
}
</style>
